/* Main part */
button {
  padding: 0;
  cursor: pointer;
  touch-action: manipulation;
}

button:disabled {
  cursor: initial;
}

.main-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.video-panel,
.finish-panel,
.low-speed-panel,
.instruction-panel {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  z-index: 101;
  opacity: 0;
}

.video-panel {
  z-index: 110;
}

.show-video-panel {
  display: block;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.video-panel .video-container {
  position: absolute;
  width: 100%;
  max-width: 720px;
  /* max-height: 100%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;

  /* background-color: rgba(0,0,0,0.5); */
  /* z-index: 1; */
  border: 0;
}

.video-container video {
  width: 100%;
  outline: none;
  transform: scale(0);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

#closeVideoButton,
#closeOffroadButton {
  display: block;
  opacity: 0;
  position: absolute;
  width: auto;
  background-color: rgba(0, 0, 0, 0);
  outline: 0;
  top: -10px;
  right: -15px;
  /* top: -70px;
	  right: -70px; */

  border: 0px;
  z-index: 200;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

#closeOffroadButton {
  opacity: 1;
  width: 60px;
  height: 60px;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 770px), screen and (max-height: 500px) {
  #closeVideoButton {
    top: -15px;
    right: -15px;
  }
}

@media screen and (max-height: 380px) {
  .video-container #closeVideoButton {
    top: -5px;
    right: -5px;
  }
}

#closeVideoButton:hover,
#closeOffroadButton:hover {
  transform: scale(1.1);
}

.video-panel .show-video-container video {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transform: scale(1);
}

.video-panel .show-video-container #closeVideoButton {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  opacity: 1;
}

.finish-panel {
  z-index: 400;
  opacity: 0;
}

.popup-window {
  box-sizing: border-box;
  position: absolute;
  /* width: 0%; */
  max-width: 379px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 20px 30px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.panel-active {
  display: block;
  opacity: 1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.popup-window {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  width: 100%;
}

.popup-window p {
  user-select: none;
  -webkit-user-select: none;
  font-family: SKODANext;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;

  text-align: center;
  letter-spacing: 0.01em;

  color: #6b6b6b;
  margin: 15px 0 25px 0;
}

.popup-window p.bold-p {
  font-family: SKODANext;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #222;
  margin: 15px 0;
}

.offroad-menu {
  z-index: 99;

  position: absolute;
  /* width: 100%; */
  left: 50%;
  transform: translate(-50%, 0);
  width: 550px;
  bottom: 1.2em;
  bottom: 30px;
  /* bottom : 5%; */
  display: flex;
  justify-content: space-between;
  transition: all 2s ease;
  -webkit-transition: all 2s ease;

  opacity: 0;
}

.offroad-menu-visible {
  opacity: 1;
  transition: all 2s ease;
  -webkit-transition: all 2s ease;
}

.offroad-menu-visible {
  display: flex;
  opacity: 1;
}

.main-buttons {
  user-select: none;
  -webkit-user-select: none;
  width: 177px;
  height: 48px;

  /* background: #C4C4C4; */
  background: rgba(0, 0, 0, 0);
  /* border: 2px solid #D1D1D1; */
  /* box-sizing: border-box; */
  border-radius: 24px;
  text-align: center;

  font-family: SKODANext;
  font-style: normal;
  font-weight: bold;
  /* font-size: 14px; */
  font-size: 80%;
  /* line-height: 24px; */

  /* align-items: center; */
  text-transform: uppercase;

  /* color: #494949; */
  color: #fff;
  /* margin-left: 10px; */
  outline: 0;
  border: 2px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.popup-window .main-buttons {
  width: 233px;
  margin: auto;
}

#restartButton {
  margin-top: 20px;
  width: 180px;
  color: #494949;
  border-color: #cacaca;
}

.small-buttons {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* position: relative; */
}

.resolution-panel .small-flat-buttons {
  width: 48px;
  height: 48px;
  border: 0px;
}

.resolution-panel .small-flat-buttons:hover {
  background: rgba(255, 255, 255, 0);
  color: #34861c;
}

.main-buttons img {
  margin-right: 10%;
  height: 50%;
}

.small-buttons img {
  margin-right: 0;
  height: 80%;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}

.main-buttons.active {
  background: #4ba82e;
  /* color:#fff; */
  border: 0 solid #d1d1d1;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  box-shadow: 0px 4px 8px rgba(75, 168, 46, 0.3);
}

@media (hover: hover) {
  .main-buttons:hover {
    background: #34861c;
    border: 0 solid #d1d1d1;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
    color: #fff !important;
    /* box-shadow: 0px 4px 8px rgba(75, 168, 46, 0.3); */
  }
}

.main-buttons:disabled {
  background: rgba(0, 0, 0, 0);
  border: 2px solid #d1d1d1;
}

.offroad-menu .main-buttons:disabled > img {
  height: 0;
  transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}

.up-pannel {
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  z-index: 105;
  justify-content: space-between;
  align-items: center;
  padding: 0 3px;
  /* margin-top: 5px; */
}

.resolution-panel {
  user-select: none;
  -webkit-user-select: none;
  z-index: 105;
  margin-left: -50px;
}

.close-panel {
  user-select: none;
  -webkit-user-select: none;
  /* position: absolute;
	  top: 0;
	  left: 0; */
  z-index: 105;
  margin-left: 20px;
}

#closeButton {
  user-select: none;
  -webkit-user-select: none;

  height: 48px;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  font-family: SKODANext;
  font-style: normal;
  font-weight: bold;
  font-size: 80%;
  text-transform: uppercase;
  color: #cacaca;
  outline: 0;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  padding: 0 0;
}

#closeButton:hover {
  color: #fff;
  /* transform: scale(1.1); */
  /* font-size: 82%; */
}

#closeButton:hover img {
  /* height:55%; */
}

#closeButton img {
  margin-right: 0px;
  height: 50%;
}

.sound-panel {
  /* position: absolute;
	  top: 0;
	  right: 0; */
  user-select: none;
  -webkit-user-select: none;
  z-index: 105;
  margin-right: 20px;
}

.sound-panel #soundButton {
  margin: 0;
  outline: 0;
  background: rgba(0, 0, 0, 0);
  border: 0;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
}

.sound-panel #soundButton:hover {
  transform: scale(1.1);
}

/* fullscreen button from 600 to */
@media screen and (max-width: 750px) {
  .offroad-menu {
    z-index: 99;

    width: 280px;
  }
  .offroad-menu .main-buttons {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .offroad-menu .main-buttons span {
    display: none;
  }

  .offroad-menu .main-buttons img {
    margin-right: 0;
    /* height: 60%; */
  }

  .my-spinner span {
    font-size: 16px;
  }
}

/* for fullscreen button */

@media screen and (max-width: 500px) {
  .offroad-menu {
    width: 220px;
  }
  .offroad-menu .main-buttons {
    width: 40px;
    height: 40px;
  }

  #spinner {
    width: 50px;
    height: 50px;
  }

  .my-spinner span {
    font-size: 14px;
  }
}

@media screen and (max-width: 342px) {
  #spinner {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  #spinner span {
    font-size: 10px;
  }

  .offroad-menu {
    width: 200px;
  }
  .offroad-menu .main-buttons {
    width: 36px;
    height: 36px;
  }
}

/*
	Fancy tooltip
  */
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  text-transform: none;
  font-family: SKODANext;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #494949;

  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 10px;
  min-width: 50px;
  max-width: 100px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  /*   font-size: 0.73rem; */
  background-color: rgba(255, 255, 255, 0.9);
  /* background-image: linear-gradient(30deg,
		rgba(59, 72, 80, 0.44),
		rgba(59, 68, 75, 0.44),
		rgba(60, 82, 88, 0.44)); */
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  /* color: #fff; */
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -7px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  /* z-index: 11; */

  border-width: 10px 10px 0px 10px;
  border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
  transition-duration: 0s; /* If the mouse leaves the element,
								  the transition effects for the
								  tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
								  slide-down effect */
  transform: translate(-50%, 2.5px) scaleY(0);
}

/* Tooltip becomes visible at hover */
/* [data-tooltip]:hover:before,
  [data-tooltip]:hover:after { */
.showtip[data-tooltip]:before,
.showtip[data-tooltip]:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
/* [data-tooltip]:hover:before { */
.showtip[data-tooltip]:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -7px) scale(1);
}
/* Slide down effect only on mouseenter (NOT on mouseleave) */
/* [data-tooltip]:hover:after { */
.showtip[data-tooltip]:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.3s;
  transform: translate(-50%, 2.5px) scaleY(1);
}

/* .showtip {
	box-shadow: 120px 80px 40px 20px #0ff;
  } */

.showtip img {
  animation: 1s ease-in 0s infinite alternate center-animate;
}

@keyframes center-animate {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

@keyframes halo-animate {
  0% {
    box-shadow: inset 0px 0 10px #78f06d, 0 0 30px #4bf82e;
  }
  100% {
    box-shadow: inset 0px 0 1px #78f06d, 0 0 1px #4bf82e;
  }
}

.showtip {
  box-shadow: inset 0px 0 10px #78f06d, 0 0 30px #4bf82e;
  animation: 1s ease-in 0s infinite alternate halo-animate;
}

/* ------- end of tooltip------ */

/* spinner */
.uk-icon {
  margin: 0;
  border: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  fill: currentcolor;
  line-height: 0;
}

svg:not(:root) {
  overflow: hidden;
}

.uk-spinner > * {
  animation: uk-spinner-rotate 1.4s linear infinite;
}

.uk-icon > * {
  transform: translate(0, 0);
}

svg {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: #4ba82e !important;
}

.uk-spinner > * > * {
  stroke-dasharray: 88px;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: uk-spinner-dash 1.4s ease-in-out infinite;
  stroke-width: 1.7px !important;
  stroke-linecap: round;
}

@keyframes uk-spinner-dash {
  0% {
    stroke-dashoffset: 88px;
  }
  50% {
    stroke-dashoffset: 22px;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 88px;
    transform: rotate(450deg);
  }
}

@keyframes uk-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.my-spinner {
  display: none !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
}

.my-spinner-active {
  display: block !important;
}

.my-spinner-label {
  font-size: 20px;
  font-weight: bold;
  color: #4ba82e;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%) !important;
  animation: none !important;
}

.loaderv-conatiner {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: #000;
  display: none;
}

#loaderVideo {
  position: absolute;
  /* height: 100%; */
  display: block;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-aspect-ratio: 16/9) {
  #loaderVideo {
    height: 100%;
  }
}

@media screen and (min-aspect-ratio: 16/9) {
  #loaderVideo {
    width: 100%;
  }
}

.persent-text {
  position: absolute;
  font-family: SKODANext;
  font-size: 2em;
  letter-spacing: 0.2rem;
  color: #fff;
  bottom: 0;
  right: 0;
  z-index: 210;
  padding: 15px;
}

/* instructions */

.instructions {
  opacity: 0;
  color: #cacaca;
  text-align: center;
  font-family: SKODANext;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 350px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 90%;
  transition-property: opacity;
  transition-duration: 1s;
}

.instruction-active {
  opacity: 1;
}

.instructions .header-part {
  max-width: 450px;
  font-weight: 400;
  font-size: 20px;
}

.instructions .text-part {
  max-width: 350px;

  font-weight: 290;
  font-size: 16px;
}

.instructions .main-part {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* max-height: 400px; */
}

.instructions .parts {
  display: none;
  opacity: 0;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.instructions .parts-active {
  opacity: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.instructions .parts .button-text {
  width: 180px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.instructions .parts span {
  margin-left: 12px;
}

#hand {
  max-width: 50px;
}

.globe-container {
  height: 100%;
  position: relative;
}

#globe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  max-width: 150px;
}

@media screen and (max-height: 500px) {
  .instructions {
    height: 200px;
  }

  .instructions .main-part {
    height: 80px;
  }

  #globe {
    max-width: 90px;
  }

  .instructions .parts-active {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .instructions .but {
    width: 450px;
  }
}

@media screen and (max-height: 368px) {
  .instructions {
    /* height: 140px; */
    margin: 35px auto;
    font-size: 14px;
  }

  .instructions .main-part {
    height: 80px;
  }

  #globe {
    max-width: 70px;
  }

  .instructions .parts-active {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .instructions .but {
    width: 450px;
  }

  .instructions .header-part {
    max-width: 90%;
    font-weight: 400;
    font-size: 18px;
  }

  .instructions .text-part {
    max-width: 90%;
    font-weight: 290;
    font-size: 14px;
  }
}

#instructionButton {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
}

.a-dialog {
  font-family: SKODANext !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  border-radius: 20px !important;
}

.a-dialog-button {
  background-color: #4ba82e !important;
  border: 0 solid #d1d1d1;
  box-shadow: 0px 4px 8px rgba(75, 168, 46, 0.3) !important;
  border-radius: 24px !important;
  text-align: center;

  font-family: SKODANext;
  font-style: normal;
  font-weight: bold;

  text-transform: uppercase;

  color: #fff;
  outline: 0;
  border: 2px solid #ffffff;
}

.a-dialog-allow-button {
  background-color: #4ba82e;
  border: 0 solid #d1d1d1;
  box-shadow: 0px 4px 8px rgba(75, 168, 46, 0.3);
}

.a-dialog-deny-button {
  color: #494949 !important;
  border-color: #494949 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.a-dialog-ok-button {
}
