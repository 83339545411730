@font-face {
  font-family: SKODANext;
  src: url("../../assets/fonts/SKODANext-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: SKODANext;
  src: url("../../assets/fonts/SKODANext-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: SKODANext;
  src: url("../../assets/fonts/SKODANext-Bold.otf") format("opentype");
  font-weight: 700;
}

.sk-app {
  height: 100%;
  background: #494949;
  position: relative;
  overflow: hidden;

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 106; //loader for offroad
    background: black;
    color: #4ba82e;

    &-video {
      //flex-grow: 100;
      width: 100%;
      // max-height:100%;
    }

    &-spinner {
      position: absolute;
      width: 60px;
      height: 60px;

      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      &_bottom {
        bottom: 33px;
        right: 110px;
      }
    }

    @media screen and (max-width: 500px) {
      &-spinner {
        width: 50px;
        height: 50px;

        &_bottom {
          right: 75px;
        }
      }

      &-percent,
      &-label {
        font-size: 13px;
      }
    }

    @media screen and (max-width: 342px) {
      &-spinner {
        width: 40px;
        height: 40px;
        font-size: 14px;

        &_bottom {
          right: 60px;
          bottom: 28px;
        }
      }

      &-percent,
      &-label {
        font-size: 10px;
      }
    }

    &-spin {
      position: absolute;
    }

    // анимация появления
    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 0ms ease;
    }

    // анимация затухания (мгновенная)
    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 500ms ease;
    }
  }

  * {
    font-family: SKODANext, sans-serif;
  }
}

// offroad change
.sk-fullscreen-button {
  width: 48px;
  height: 48px;
  position: absolute;
  // right: 40px;
  // bottom: 40px;
  right: 40px; // offroad
  bottom: 30px; // offroad

  cursor: pointer;
  background: url("../../assets/icons/icons-enter-full-default.svg") 0 0
    no-repeat;
  background-size: contain;
  z-index: 110;
  // z-index: 200;
  &:hover {
    background-image: url("../../assets/icons/icons-enter-full-hover.svg");
  }
  &--entered {
    background-image: url("../../assets/icons/icons-exit-full-default.svg");
    &:hover {
      background-image: url("../../assets/icons/icons-exit-full-hover.svg");
    }
  }
  &--hide {
    display: none;
  }

  @media screen and (max-width: 500px) {
    width: 40px;
    height: 40px;
    right: 16px;
  }

  @media screen and (max-width: 342px) {
    width: 36px;
    height: 36px;
    right: 10px;
  }
}
