.sk-exterior {
  height: 100%;
  position: relative;

  $this: &;

  &__prev-frame,
  &__next-frame {
    width: 60px;
    height: 60px;
    position: absolute;
    top: calc(50% - 30px);
    cursor: pointer;
    z-index: 2;
  }

  &__prev-frame {
    background: url('../../assets/exterior/L.png') no-repeat 0 0;
    left: 0;
  }

  &__next-frame {
    background: url('../../assets/exterior/R.png') no-repeat 0 0;
    right: 0;
  }

  &--mobile {
    #{$this}__prev-frame,
    #{$this}__next-frame {
      width: 35px;
      height: 35px;
      top: calc(50% - 17px);
      background-size: cover;
    }
  }
}

.sk-exterior-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;

  &__top {
    width: 100%;
    height: 100%;
    flex-shrink: 5;
  }

  &__bottom {
    width: 100%;
    height: 100%;
    flex-shrink: 5;
  }

  &__center {
    width: 100%;
    height: 100%;
    max-height: 720px;
    flex-grow: 2;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    background: repeat 0 center;
  }

  &__canvas {
    /*max-width: 1280px;*/
    /*max-height: 720px;*/
    width: 100%;
    height: 100%;
  }
}

.sk-exterior-canvas-wrapper {
  position: relative;
  max-width: 1280px;
  max-height: 720px;
  width: 100%;
}
