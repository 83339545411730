.sk-info-spot {
  background: #4ba82e url('../../assets/infospot/cross.svg') no-repeat center
    center;
  background-size: 50%;
  filter: drop-shadow(0px 4px 8px rgba(200, 201, 204, 0.2));
  border-radius: 100%;
  display: none;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  $this: &;

  box-shadow: 0 0 20px #4bf82e;

  // &::before {
  //   filter: drop-shadow(0px 4px 4px rgba(200, 201, 204, 0.2));
  //   background: rgba(75, 168, 46, 0.3);
  //   background-size: 25%;
  //   width: 200%;
  //   height: 200%;
  //   border-radius: 100%;
  //   display: block;
  //   content: '';
  //   position: absolute;
  //   left: -50%;
  //   top: -50%;
  // }

  &--viewed {
    background-color: #c4c4c4;
    &:hover {
      &::after {
        background-color: #c4c4c4 !important;
      }
    }
  }

  &:hover {
    z-index: 4;
    &::after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 100%;
      width: 100%;
      height: 100%;

      background: #4ba82e url('../../assets/infospot/cross.svg') no-repeat
        center center;
      background-size: 50%;
    }
    &::before {
      filter: drop-shadow(0px 4px 8px rgba(200, 201, 204, 0.2));
      background: rgba(75, 168, 46, 0.3);
      background-size: 25%;
      width: 200%;
      height: 200%;
      border-radius: 100%;
      display: block;
      content: '';
      position: absolute;
      left: -50%;
      top: -50%;
    }
  }

  &--show {
    display: block;
  }

  &--mobile#{$this}--video {
    background-image: url('../../assets/infospot/play.svg');
    background-position: 68% 57%;
  }

  &--video {
    &:hover {
      &::after {
        background-image: url('../../assets/infospot/play.svg');
        background-position: 68% 57%;
      }
    }
  }
}
