.sk-nav-bar {
  top: 24px;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  &__item {
    cursor: pointer;
    height: 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #CACACA;
    margin: 0 10px;
    transition: color 0.1s ease;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 5px;
      background-color: #4ba82E;
      width: 100%;
      left: -8px;
      bottom: -5px;
      padding: 0 8px;
      opacity: 0;
      transition: opacity 0.1s ease;
    }

    &--active {
      color: #4ba82e;

      &::after {
        opacity: 1;
      }
    }

    &--disable {
      display: none;
    }

    &:hover {
      color: #4ba82e;
    }
  }
}
