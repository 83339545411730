.sk-content-popup {
  z-index: 3;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  $this: &;

  &__header-image-container {
    background: white;
    display: flex;
    align-items: center;
  }

  &--type-video {
    #{$this}__header-image-container {
      cursor: pointer;
      position: relative;
      &:after {
        position: absolute;
        display: block;
        content: '';
        top: calc(50% - 39px);
        left: calc(50% - 38px);
        width: 78px;
        height: 79px;
        background: url('../../assets/icons/marker_play.svg') 0 0 no-repeat;
        pointer-events: none;
      }
    }
  }

  &--mobile {
    z-index: 5;
  }

  &--full-screen {
    background: #000;
    z-index: 6;
  }

  &--video {
    width: 100%;
  }

  &__card {
    width: 360px;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 1px;

    &--mobile {
      width: 100%;
      height: 100%;
      #{$this}__header-image {
        width: 100%;
        height: 100%;
      }
      #{$this}__text {
        height: 100%;
      }
      #{$this}__content {
        height: 100%;
      }
    }

    &--horizontal {
      display: flex;
      background: white;

      #{$this}__header-image-container {
        order: 2;
        min-width: 50%;
        max-width: 50%;
        //align-self: center;
      }

      #{$this}__content {
        order: 1;
      }

      #{$this}__text {
      }

      #{$this}__title {
      }
    }

    &--position {
      &_left {
        left: -360px;
      }

      &_middle {
        // по центру
      }

      &_right {
        right: -360px;
      }

      @media (max-width: 1168px) {
        &_right {
          position: absolute;
          right: 44px;
        }

        &_left {
          position: absolute;
          left: 44px;
        }
      }
    }
  }

  &__close-button {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../assets/icons/close.svg') 0 0 no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    color: #222222;
    padding: 18px 26px;
    background: #fff;
  }

  &__text {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #6b6b6b;
    padding: 0 26px 44px;
    background: #fff;
  }

  &__header-image {
    min-height: 180px;
    max-height: 210px;
    height: auto;
    width: 360px;
    display: block;
    object-fit: contain;
  }

  // анимация появления
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: opacity 300ms ease;
  }

  &--mobile {
    &.sk-content-popup-enter-active {
      transition: opacity 0ms ease;
    }
  }

  // анимация затухания (мгновенная)
  &-exit {
    display: none;
  }

  &-exit-active {
    display: none;
  }
}
